<template>
    <div class="ArticleDetails" id="ArticleDetails">
      <BSView :config="configOne" @pullingDown="pullingDownFunOne()" :isDisplay='false' :isPullDisplay='false'>
        <div class="detailsTop" id="detailsTop">
          <!-- <div @click="test">按钮</div> -->
          <div v-show="panduan()">
            <div class="title">{{articleDetails.title}}</div>
              <div class="deadilsTime">
                {{articleDetails.time}}
                <div v-if="articleDetails.type==1 || articleDetails.type==3" class="jiantou"  @click="switchover">
                  <img v-if="!isCut" src="@/../public/image/jiantouTop.png" alt="向上箭头">
                  <img v-if="isCut" src="@/../public/image/jiantouBottom.png" alt="向下箭头">
                </div>
              </div>
              <div v-if="articleDetails.type==2" class="content" v-html="articleDetails.content"></div>
              <div v-if="(articleDetails.type==1 || articleDetails.type==3)&&isCut" class="content">
                {{articleDetails.content}}
              </div>
              <div class="detailsBtnBox" v-if="jtDisplay">
                  <div class="intoChat" @click="intoChat">
                    <v-btn width="100%" height="40px" large color="#f5f5f5" dark>进入聊天</v-btn>
                  </div>
                  <div class="otherBtn">
                      <div @click="transmit">
                        <v-btn color="#f5f5f5" fab small dark height="40px" width="40px">
                          <img src="@/../public/image/zhaunfa.png" alt="链接">
                        </v-btn>
                      </div>
                      <div @click="gotoHome">
                        <v-btn color="#f5f5f5" fab small dark height="40px" width="40px">
                          <img src="@/../public/image/yujing.png" alt="其他预警">
                        </v-btn>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="detailsMiddle" v-show="panduanTwo()">
          <div class="title">
            <div class="titleLft">
              <span>
                {{Object(articleDetails.list_data).title_target}}
              </span>
            </div>
            <div class="titleRight">
              <span>
                {{Object(articleDetails.list_data).title_value}}
              </span>
            </div>          
          </div>
          <ul class="content">
            <li v-if="Object(articleDetails.list_data).data&&Object(articleDetails.list_data).data.length == 0" style="color:#999999;justify-content: center;padding-top:20px">
              暂无数据
            </li>
            <li v-for="(item,index) in Object(articleDetails.list_data).data" :key="index">
              <div>
                <img v-if="false" src="@/../public/image/chenggong.png" alt="">
                <img v-if="false" src="@/../public/image/yyjinggao3.png" alt="">
                <span>{{item[0]}}</span>
              </div>
              <div>
                <div>{{item[1]}}</div>
              </div>
            </li>
          </ul>
        </div>
      </BSView>
      <div class="cancontent" v-if="isDisplay" @click="change">
        <img :src="imgUrl" alt="">
        <div>长按图片转发</div>
      </div>
    </div>
</template>

<script>
import BSView from "@/components/BSView.vue";
import html2canvas from 'html2canvas';
import md5 from 'js-md5'
export default {
  name: 'ArticleDetails',
  components: {
    BSView
  },
  data () {
    return {
      statementHeight:'',
      statementSrc:'',//报表地址
      jtDisplay:true,//绘制图片的时候控制按钮是否显示
      isDisplay:false,//绘制的图片盒子
      imgUrl:'',//绘制的图片的地址
      articleDetails:[],
      configOne: {
        //滚动条
        id: "BSViewOne",
        bs: "",
        style: {
          width:'100%',
          bottom: 0,
          top: 0,
        },
        BSConfig:{
          pullDownRefresh:{
            threshold: 0,
            stop: 0
          },
          click: true,
        },
        disabledDown: false,
        disabledUp: false,
      },
      isCut:false,//是否显示内容
      style:'{font-size:16px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}',
      dataList:[//预警数据
      ],
    }
  },
  methods: {
    panduan() {
      return (this.articleDetails.type==1 || this.articleDetails.type==3 || this.articleDetails.type==2)&&this.articleDetails.list_type!=2
    },
    panduanTwo() {
      return (this.articleDetails.type==1 || this.articleDetails.type==3)&&this.articleDetails.list_type!=2
    },
    saveFile(data, filename) {//保存图片
      const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      save_link.href = data;
      save_link.download = filename;

      const event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      save_link.dispatchEvent(event);
    },
    transmit() {//转发为图片
      if($core.getUrlKey("isHome")) {
        this.getUserActive('访问主题','转发')
      }else {
        this.getUserActive('打开消息','转发')
      }
      let that = this
      // console.log(document.querySelector('#canvasContent'),document.querySelector('#canvasContent').clientHeight)
      this.jtDisplay = false
      this.isCut = true
      this.style='{}'
      this.pullingDownFunOne()
      setTimeout(() => {
        let mheight = document.querySelector('#canvasContent').clientHeight
        html2canvas(document.querySelector('#canvasContent'),{useCORS: true,allowTaint: true,height:mheight}).then(function(canvas) {
          let imgUrl = canvas.toDataURL("image/jpeg", 1);
          that.imgUrl = imgUrl;
          // that.saveFile(that.imgUrl, new Date().toLocaleString())
          that.isDisplay = true
        });
      }, 1000)
        
    },
    change() {
      // console.log(this.isDisplay)
      this.isDisplay = false
      this.jtDisplay = true
    },
    gotoHome() {//其他预警按钮
      // console.log("其他的预警")
      if($core.getUrlKey("isHome")) {
        this.getUserActive('访问主题','前往主页')
      }else {
        this.getUserActive('打开消息','前往主页')
      }
      this.$router.push({
        //核心语句
        path: "/", //跳转的路径
        query: {//路由传参时push和query搭配使用 ，作用时传递参数
          wechat_id:$core.getUrlKey('wechat_id'),
          isArticle:'yes'//是否从详情页面进入主页
        },
      });
    },
    pullingDownFunOne() {//下拉刷新
      this.configOne.disabledDown = true;
    },
    switchover() {//显示多余的内容
      // console.log(1)
      this.isCut = !this.isCut
      // if(this.isCut) {
      //   this.style='{}'
      // }else {
      //   this.style=''
      // }
      setTimeout(() => {
        this.statementHeight = window.innerHeight - window.document.getElementById("detailsTop").clientHeight
      }, 500);
      this.$nextTick(()=> {
        this.configOne.bs.refresh();
      })
    },
    intoChat() {//进入聊天按钮
      if($core.getUrlKey("isHome")) {
        this.getUserActive('访问主题','发起聊天')
      }else {
        this.getUserActive('打开消息','发起聊天')
      }
      var userIdList=[]
      if(this.articleDetails.type==2) {
        wx.invoke("selectEnterpriseContact", {//选人接口
          "fromDepartmentId": -1,// 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
          "mode": "multi",// 必填，选择模式，single表示单选，multi表示多选
          "type": ["department", "user"],// 必填，选择限制类型，指定department、user中的一个或者多个
          "selectedUserIds": []// 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        },function(res){
          if (res.err_msg == "selectEnterpriseContact:ok") {
            if(typeof res.result == 'string') {
              res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
            }
            var selectedUserList = res.result.userList; // 已选的成员列表
            for (var i = 0; i < selectedUserList.length; i++) {
              userIdList.push(selectedUserList[i].id)
            }
            wx.openEnterpriseChat({//创建聊天接口
              userIds: userIdList.join(';'),    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
              groupName: this.articleDetails.title,  // 必填，会话名称。单聊时该参数传入空字符串""即可。
              success: function(res) {
                  // 回调
                  console.log(res.chatId)
              },
              fail: function(res) {
                  if(res.errMsg.indexOf('function not exist') > -1){
                      alert('版本过低请升级')
                }
              }
            });
          }
        });
      }
      if(this.articleDetails.type==1) {
        $core.request('front/chat/create', res => {
          // console.log(res)
          let that = this
          wx.openEnterpriseChat({//创建聊天接口
            userIds: res.data.join(';'),
            // externalUserIds: 'wmEAlECwAAHrbWYDOK5u3Bf13xlYDAAA;wmEAlECwAAHibWYDOK5u3Af13xlYDAAA', // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
            groupName: this.articleDetails.title,  // 必填，会话名称。单聊时该参数传入空字符串""即可。
            chatId: '',
            success: function(result) {
                // alert(result.chatId)
                $core.request('front/chat/success', ret => {
                  if(ret.status == 200) {
                    console.log(ret,"成功")
                  }
                },{
                  'id':that.$route.query.id,
                  "chatid":result.chatId
                });
            },
            fail: function(res) {
              // alert(res)
              console.log(res)
                if(res.errMsg.indexOf('function not exist') > -1){
                    alert('版本过低请升级')
              }
            }
          });         

        },{
          'id':this.$route.query.id
        });
        // alert("智能运营")
        
      }

      if(this.articleDetails.type==3) {
        alert("测试文章，不可发布聊天")
      }
      
    },
    getWxJsJdk(){//使用微信的接口鉴权
      $core.request('wechat/wx_sign', res => {
        wx.config({
          beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.corpid, // 必填，企业微信的corpID
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.noncestr, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名，见附录1
          jsApiList: ['hideOptionMenu','openEnterpriseChat',] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.hideOptionMenu();
            wx.checkJsApi({
              jsApiList: ['openEnterpriseChat'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function(res) {
              }
            });

            $core.request('wechat/wx_sign', ret => {
              wx.agentConfig({
                corpid: ret.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: 1000003, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: ret.timestamp, // 必填，生成签名的时间戳
                nonceStr: ret.noncestr, // 必填，生成签名的随机串
                signature: ret.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                jsApiList: ['selectExternalContact','openEnterpriseChat',] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              });
              wx.error(function(res){//通过error接口处理失败验证
                console.log('执行失败');
              });
            },{
              url:encodeURIComponent(location.href.split('#')[0]),
              is_corp:false,
              wechat_id:$core.getUrlKey('wechat_id')
            });

        });
        wx.error(function(res){//通过error接口处理失败验证
          console.log('执行失败');
        });
      },{
        url:encodeURIComponent(location.href.split('#')[0]),
        is_corp:true,
        wechat_id:$core.getUrlKey('wechat_id')
      });
    },
    getArticleDetails(id) {
      $core.request('front/index/msg_detail', res => {
        if(res.status == 200) {
          this.articleDetails = res.data
          this.statementSrc = this.articleDetails.list_data.url
          if((res.data.type==1 || res.data.type==3)&&res.data.list_type==2) {
            if($core.getUrlKey('isHome')) {
              if(!$core.getLocal("statementSrc")) {
                $core.setLocal("statementSrc",this.statementSrc);
                window.location.href = this.statementSrc
              }else {
                document.title = '数加加'              
                if($core.getLocal("homeurl")) {
                  window.location.href = $core.getLocal("homeurl")
                }
                this.$router.replace({
                  path: "/",
                  query: {//路由传参时push和query搭配使用 ，作用时传递参数
                    wechat_id:$core.getUrlKey('wechat_id'),
                  },
                });
              }
            }else {
              $core.setLocal("statementSrc",this.statementSrc);
              window.location.href = this.statementSrc
            }
          }
          setTimeout(() => {
              this.statementHeight = window.innerHeight - window.document.getElementById("detailsTop").clientHeight
              // console.log(this.statementHeight)         
          }, 1000);
          this.$nextTick(()=> {
            // console.log(this.statementHeight)
            this.configOne.bs.refresh();
            // console.log(this.configOne.bs)
          })
          
        }
      },{
        'msg_id':id
      });
    },
    wxAuthorization() {//微信授权，获取token
      if(!$core.getLocal($core.getUrlKey('wechat_id'))) {
        if(!$core.getUrlKey('token')) {
            window.location.href = "https://jiajia1.aitaiyi.cn/api/wechat/wx_oauth?wechat_id="+$core.getUrlKey('wechat_id')+"&back_url="+encodeURIComponent(location.href.split('#')[0])
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }else {
            $core.setLocal($core.getUrlKey('wechat_id'),$core.getUrlKey('token'))
            $core.setLocal('token',$core.getUrlKey('token'))
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }
        }else {
          if($core.getLocal($core.getUrlKey('wechat_id')) != $core.getLocal('token')) {
            if(!$core.getUrlKey('token')) {
              window.location.href = "https://jiajia1.aitaiyi.cn/api/wechat/wx_oauth?wechat_id="+$core.getUrlKey('wechat_id')+"&back_url="+encodeURIComponent(location.href.split('#')[0])
              $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
            }else {
              $core.setLocal($core.getUrlKey('wechat_id'),$core.getUrlKey('token'))
              $core.setLocal('token',$core.getUrlKey('token'))
              $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
            }
          }
        }
    },
    CalcuMD5(pwd) {
      let num = String(Math.floor(Math.random() * (10000 - 1)) + 1)
      pwd = md5(num+pwd);
      return pwd;
    },
    getUserActive(name,event) {
      $core.request('growdata/add', res => {
        // console.log(res)
      },{
        'id':$core.getLocal('ArticleDetailsMsgId'),//消息id
        "page_id":$core.getLocal('ArticleDetailsId'),//页面id
        'name':encodeURI(name),//事件类型名称
        'event':encodeURI(event),//事件
        'type':1,//消息还是列表
      });
    },
  },
  mounted() {
    // 给当前页面创建唯一id
    if(!$core.getLocal('ArticleDetailsId')) {
      let now = new Date();
      let nowTime = now.getTime();//获取当前时间时间戳
      $core.setLocal('ArticleDetailsId',this.CalcuMD5(String(nowTime)))
      $core.setLocal('ArticleDetailsMsgId',this.$route.query.id)
      if($core.getUrlKey("isHome")) {
        this.getUserActive('访问主题','打开')
      }else {
        this.getUserActive('打开消息','打开')
      }
    }
  },
  beforeDestroy() {
    if($core.getUrlKey("isHome")) {
      this.getUserActive('访问主题','关闭')
    }else {
      this.getUserActive('打开消息','关闭')
    }
  },
  destroyed() {
    $core.clearLocal('ArticleDetailsId')
    $core.clearLocal('ArticleDetailsMsgId')
  },
  created() {
    document.title = '详情'
    this.wxAuthorization()
    this.getWxJsJdk()
    this.getArticleDetails(this.$route.query.id)
  }
};
</script>

<style lang="less" scoped>
  img {
    width: 100%;
  }
  #app .ArticleDetails{ 
    // overflow-y: auto;
  }
  .cancontent {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.336);
    color: white;
    font-size: 14px;
    img {
      margin-top: 50px;
      width: 80%;
      height: 80%;
    }
  }
  .ArticleDetails {

    background: #f2f2f2;
    .detailsTop {
      background: white;
      padding: 10px 15px;
      .title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 6px;
      }
      .deadilsTime {
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .content {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 10px;
        p img {
          width: 100%;
        }
      }
      .jiantou {
        text-align: center;
        // margin-top: 5px;
        display: inline-block;
        img {
          width: 16px;
          vertical-align: middle;
        }
      }

      .detailsBtnBox {
        display: flex;
        margin-top: 10px;
        .intoChat {
          width: 50%;
          // height: 42px;
          // line-height: 42px;
          // border-radius: 10px;
          // background-color: rgba(245, 245, 245, 1);
          // font-size: 16px;
          text-align: center;
          /deep/ .v-btn__content {
              color: #0174D9;
          }
          /deep/ .v-btn--contained {
              box-shadow: none;
          }
          span {
            color: #0174D9;
          }
        }
        .otherBtn {
          display: flex;
          width: 50%;
          justify-content: space-around;
          font-size: 12px;
          align-items: center;
          padding-left: 30px;
          /deep/ .v-btn--fab.v-btn--contained {
              box-shadow: none;
          }
          div {
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
              width: 20px;
            }
          }
        }
      }
    }
    // 中间预警的详情信息
    .detailsMiddle {
      background: white;
      padding: 10px 15px;
      margin-top: 10px;
      padding-top: 0;
      padding-bottom: 25px;
      .title {
        display: flex;
        padding: 5px 0px;
        // font-size: 16px;
        border-bottom: 1px solid #f2f2f2;
        .titleLft,titleRight {
          width: 50%;
          font-size: 12px;
          height: 44px;
          color: #999999;
        }
        .titleLft {
          line-height: 44px;
          span:nth-child(1) {
            margin-right: 20px;
          }
        }
        .titleRight {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #999999;
          font-size: 12px;
          // div {
          //   text-align: center;
          //   line-height: 20px;
          //   span {
          //     font-size: 12px;
          //   }
          //   span:nth-child(1) {
          //     color: #0174D9;
          //   }
          //   span:nth-child(2) {
          //     color: #999999;
          //   }
          // }
          
        }
      }

      .content {
        padding: 0;
        li {
          display: flex;
          padding: 5px 0px;
          justify-content: space-between;
          div {
            // width: 50%;
            height: 40px;
            line-height: 40px;
            span {
              vertical-align: middle;
            }
            img {
              width: 20px;
              margin-right: 20px;
              vertical-align: middle;
            }
          }
          div:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            line-height: 44px;
            // vertical-align: middle;
            div {
              text-align: right;
              line-height: 44px;
              // vertical-align: middle;
            }
          }
        }
      }
    }
  }
</style>